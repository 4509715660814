import React, { Component } from "react"
import { Helmet } from "react-helmet"
import TopMenu from "../components/TopMenu"
import BottomMenu from "../components/BottomMenu"
import images from "../assets/images"

class Erica extends Component {
  render() {
    return (
      <>
        <TopMenu />
        <main className="erica">
          <Helmet title={"Storia Erica Scoparia - Costruzione Artigianale"}>
            <meta
              name="description"
              content="Storia Lericas - L'erica scoparia per noi è molto importante, qua la elogiamo."
            />
          </Helmet>
          <section className="description">
            <h1>Erica</h1>
            <span>
              Il genere Erica (nome di origine oscura, usato da Plinio) è
              vastissimo, comprende circa 650 specie, si estende dall'Europa
              atlantica e mediterranea ai monti tropicali africani, fino
              all'Africa meridionale dove assume il massimo sviluppo. La
              distribuzione delle eriche intorno al Mediterraneo è ritenuta una
              sorta di relitto della vegetazione montana subtropicale del
              Terziario medio che si è differenziata nelle specie più xerofile
              nella regione mediterranea e nelle specie più mesofile (piante che
              esigono una mediocre quantità di acqua, intermedie tra le xerofile
              e le igrofile) diffuse nella regione atlantica, mentre il
              diffondersi delle specie sudafricane è spiegato con una espansione
              più tardiva, legata al graduale spostarsi verso sud delle zone
              calde.
            </span>
          </section>
          <iframe
            style={{
              width: "100vw",
              height: "calc(100vw / 560 * 315)",
              maxHeight: "100vh",
            }}
            src="https://www.youtube.com/embed/fBb3f7ZNgDE?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <section className="description">
            <h1 style={{ textAlign: "right" }}>Caratteristiche</h1>
            <span>
              Le piante appartenenti a questo raggruppamento si presentano come
              fruttici (alberelli) di varie dimensioni, ad accrescimento
              alquanto lento, non di rado con foglie piccole (microfille),
              persistenti, fiori di varia foggia singoli o riuniti, frutti a
              capsula o a bacca. Sono piante generalmente adatte a terreni
              fortemente acidi e dispongono di una particolare micorrizia
              (funghi sotterranei che vivono in simbiosi con le parti terminali
              delle radici delle piante) che permette loro di trarre nutrimento
              da substrati particolarmente difficili, sui quali solo i funghi
              sono in grado di compiere la funzione di rimettere in circolo le
              sostanze nutritive.
            </span>
          </section>
          <section>
            <h1 className="partners-title">Collaborazioni</h1>
            <div className="partners">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://sites.google.com/view/granathakids/piattaforma-didattica/produzioni/coperture-e-rivestimenti"
                className="partner-container"
              >
                <img alt="granatha-kids" src={images.granathakids} />
                <h3>Granatha Kids</h3>
                <div>
                  Esplora la nostra collaborazione con i nostri amici di
                  "Granatha Kids"
                </div>
              </a>
            </div>
          </section>
        </main>
        <BottomMenu />
      </>
    )
  }
}

export default Erica
